
// @ts-ignore
import React from 'react';
import { Link } from 'gatsby';

type Props = {
  currentPage: number
  numPages: number
  slug: string
};
import {PageNavigation, PageNavigationList} from '../components/elements/page-navigation'

const Pagination = ({
                      currentPage,
                      numPages,
                      slug
                    }: Props) => {


  const isFirst = currentPage === 1 || currentPage === undefined
  const isLast = currentPage === numPages
  const prevPage =  slug +   (currentPage - 1 === 1 ? "" : (currentPage - 1).toString())
  const nextPage =  slug + (currentPage + 1).toString()

  return (
    <PageNavigation>
      <PageNavigationList>
        <li>
          {!isFirst && (
            <Link
              to={prevPage}
              rel="prev"
              state={{ direction: "left" }}
            >
              ← Previous Page
            </Link>
          )}
        </li>
        <li>
          {!isLast && (
            <Link
              to={nextPage}
              rel="next"
              state={{ direction: "right" }}
            >
              Next Page →
            </Link>
          )}
        </li>
      </PageNavigationList>
    </PageNavigation>
  );
};

export default Pagination;
