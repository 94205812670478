import React from "react"
import styled from '@emotion/styled'

export const PageNavigation = styled.nav`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`

export const PageNavigationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`
